import React from 'react';
// import '../css/pdf-table.css';

const Opportunities = () => {
  return (
    <div>
      <div className="opportunity">
        <h1>CONSULTATIO FELLOWSHIP IN CIVIS, A NON-PROFIT ORGANIZATION</h1>
        <table>
          <tbody>
            <tr>
              <th>Official Notification</th>
              <td><a href="https://drive.google.com/file/d/Call-for-apps-CF-24.pdf/view">Call-for-apps-CF-24.pdf - Google Drive</a></td>
            </tr>
            <tr>
              <th>Link for Apply Online</th>
              <td><a href="https://www.civis.org.in/recruitment">Civis Recruitment Application Form</a></td>
            </tr>
            <tr>
              <th>Deadline</th>
              <td>May 19, 2024</td>
            </tr>
          </tbody>
        </table>
        <h2>Consultatio Fellowship in Civis</h2>
        <p>Civis offers the Consultatio Fellowship for new law graduates interested in public policy in India. Supported by Manupatra, it provides hands-on experience in various aspects of law-making and citizen engagement.</p>
        <table>
          <tbody>
            <tr>
              <th>Fellowship Duration</th>
              <td>1 year full-time in-person fellowship</td>
            </tr>
            <tr>
              <th>Roles and Responsibilities</th>
              <td>
                <ul>
                  <li>Facilitating and managing public consultations on draft laws and policies</li>
                  <li>Ideating and promoting citizen participation in lawmaking</li>
                  <li>Leading and managing volunteer teams</li>
                  <li>Building partnerships with public officials and institutions</li>
                  <li>Working on outreach and communications</li>
                  <li>Assisting in platform functioning and feedback gathering</li>
                </ul>
              </td>
            </tr>
            <tr>
              <th>Eligibility</th>
              <td>
                <ul>
                  <li>Law degree essential - 0-2 years of policy analysis experience preferred</li>
                  <li>Experience with law reviews/journals/magazines editing preferred</li>
                </ul>
              </td>
            </tr>
            <tr>
              <th>Required Skills</th>
              <td>
                <ul>
                  <li>Ability to learn content management system</li>
                  <li>Interest in civic engagement</li>
                  <li>Preferred: Experience in mobilizing students for college clubs/societies</li>
                </ul>
              </td>
            </tr>
            <tr>
              <th>Stipend</th>
              <td>Rs. 45,000 PM</td>
            </tr>
            <tr>
              <th>Location</th>
              <td>Mumbai</td>
            </tr>
          </tbody>
        </table>
        <h3>How to Fill Application Form Step by Step Process</h3>
        <ul>
          <li>Click on the provided link for the application form.</li>
          <li>Enter your Email ID.</li>
          <li>Choose “Yes” to apply for the Consultatio Fellowship.</li>
          <li>Proceed to the next page.</li>
          <li>Enter your Full Name and Mobile number.</li>
          <li>Provide reasons for your interest in the role and why you are suitable for it.</li>
          <li>Rate your proficiency levels.</li>
          <li>Choose your relocation status to Mumbai.</li>
          <li>Upload your updated Resume.</li>
          <li>Click on the Submit button.</li>
        </ul>
        <p>You’ll receive a confirmation of successful submission either on the screen or via email.</p>
      </div>

      <div className="opportunity">
        <h1>CONSULTANTS IN ED DIVISION OF THE MINISTRY OF EXTERNAL AFFAIRS</h1>
        <table>
          <tbody>
            <tr>
              <th>Official Notification Link</th>
              <td><a href="https://www.mea.gov.in/Advertisement-Division-2024.pdf">Advertisement-Division-2024.pdf (mea.gov.in)</a></td>
            </tr>
            <tr>
              <th>Link for Apply Online</th>
              <td><a href="mailto:aopfsec@mea.gov.in">aopfsec@mea.gov.in</a></td>
            </tr>
            <tr>
              <th>Deadline</th>
              <td>14 May 2024</td>
            </tr>
          </tbody>
        </table>
        <h2>Consultants in ED Division of the Ministry of External Affairs</h2>
        <table>
          <tbody>
            <tr>
              <th>Post</th>
              <td>Consultant in ED Division of the Ministry of External Affairs Headquarters, New Delhi</td>
            </tr>
            <tr>
              <th>Period of Consultancy</th>
              <td>Initially 1 year, extendable based on performance and mutual agreement.</td>
            </tr>
            <tr>
              <th>Nature of Duties</th>
              <td>
                <ul>
                  <li>Monitoring and follow-up of legal issues</li>
                  <li>Providing legal advice on arbitration cases</li>
                  <li>Attending court hearings and preparing legal briefs</li>
                  <li>Advising on UNCITRAL and International Solar Alliance matters</li>
                  <li>Participating in treaty interpretation and negotiations</li>
                  <li>Analyzing legal issues and recommending actions</li>
                  <li>Any other duties assigned by Head of Division</li>
                </ul>
              </td>
            </tr>
            <tr>
              <th>Job Location</th>
              <td>Ministry of External Affairs, New Delhi</td>
            </tr>
            <tr>
              <th>Qualifications/Essential Criteria</th>
              <td>
                <ul>
                  <li>Indian national</li>
                  <li>Post Graduate Degree in Law (LLM) with specialization in arbitration/commercial/investment matters</li>
                  <li>Minimum 3 years post-LLM work experience</li>
                  <li>Registered to practice before Indian courts</li>
                  <li>Age between 26-45 years</li>
                </ul>
              </td>
            </tr>
            <tr>
              <th>Desirable Criteria</th>
              <td>
                <ul>
                  <li>Previous experience in investment treaty arbitration or investment treaty law in the Government of India</li>
                  <li>Strong written and spoken English skills</li>
                  <li>Proficiency in computer applications</li>
                </ul>
              </td>
            </tr>
            <tr>
              <th>Remuneration & Entitlements</th>
              <td>
                <ul>
                  <li>Maximum remuneration of Rs. 10 lakh per annum</li>
                  <li>Strictly contractual, no additional benefits like PF, Pension, etc.</li>
                  <li>No HRA admissible</li>
                  <li>Full-time engagement with specified working hours</li>
                  <li>Paid leave subject to conditions</li>
                  <li>Travel expenses covered by Ministry if required</li>
                </ul>
              </td>
            </tr>
            <tr>
              <th>How to Apply</th>
              <td>
                <ul>
                  <li>Submit application in prescribed proforma along with supporting documents</li>
                  <li>Offline mode: By registered post to Ministry of External Affairs, New Delhi</li>
                  <li>Online mode: Via email to <a href="mailto:aopfsec@mea.gov.in">aopfsec@mea.gov.in</a></li>
                  <li>Shortlisted candidates will be notified for interview</li>
                  <li>No TA/DA provided for interview</li>
                  <li>Final selection based on interview performance</li>
                </ul>
              </td>
            </tr>
            <tr>
              <th>Application Deadline</th>
              <td>May 14, 2024, 17:30 hrs</td>
            </tr>
          </tbody>
        </table>
      </div>

      <div className="opportunity">
        <h1>NATIONAL LAW UNIVERSITY DELHI CENTRE FOR TRANSNATIONAL COMMERCIAL LAW</h1>
        <h2>CALL FOR APPLICATIONS FOR RESEARCH & TEACHING ASSOCIATE</h2>
        <table>
          <tbody>
            <tr>
              <th>Official Notification Link</th>
              <td><a href="https://www.nludelhi.ac.in/CTCL-Call-for-Applications">CTCL Call for Applications for Research & Teaching Associate - National Law University Delhi (nludelhi.ac.in)</a></td>
            </tr>
            <tr>
              <th>Link for Apply Online</th>
              <td><a href="https://forms.gle/CmSvxnaXRmNPRA6M6">https://forms.gle/CmSvxnaXRmNPRA6M6</a></td>
            </tr>
            <tr>
              <th>Deadline</th>
              <td>23rd May 2024</td>
            </tr>
          </tbody>
        </table>
        <p>The Centre for Transnational Commercial Law (CTCL) has the mandate to design and conduct courses (Certificate, Diploma and PG on Transnational Comparative Commercial Law and Practice), conduct seminars/conferences, workshops, debates/discussions, clinics, trainings, etc. The Centre liaises with institutions, professionals, NGOs, governments, public authorities, industry, corporates, for providing consultancy, conducting research and undertaking projects.</p>
        <table>
          <tbody>
            <tr>
              <th>Responsibilities</th>
              <td>
                <ul>
                  <li>To develop and coordinate the ODL courses that primarily run on weekends</li>
                  <li>To provide research support for ongoing projects and related tasks</li>
                  <li>To research and compile information on specific areas and topics</li>
                  <li>To assist in documentation and preparation of project reports and Centre publications</li>
                  <li>To provide logistical and administrative support for Centre’s activities</li>
                </ul>
              </td>
            </tr>
            <tr>
              <th>Nature of Work</th>
              <td>It is a full-time work, based out of the National Law University Delhi campus at Dwarka, Sector -14, New Delhi - 110078</td>
            </tr>
            <tr>
              <th>Eligibility</th>
              <td>
                <ul>
                  <li>A degree in law (LLB/LLM/PhD)</li>
                  <li>One-year post-qualification academic or professional experience</li>
                  <li>Excellent research, writing and communication skills</li>
                </ul>
              </td>
            </tr>
            <tr>
              <th>Duration</th>
              <td>12 months (June 2024 to May 2025)</td>
            </tr>
            <tr>
              <th>Remuneration</th>
              <td>A consolidated pay of Rs.50,000 per month</td>
            </tr>
            <tr>
              <th>Application Process</th>
              <td>Interested candidates may apply <a href="https://forms.gle/CmSvxnaXRmNPRA6M6">here</a>. Contact email: <a href="mailto:ctcl@nludelhi.ac.in">ctcl@nludelhi.ac.in</a></td>
            </tr>
            <tr>
              <th>Selection Process</th>
              <td>Shortlisted candidates will be interviewed for selection. Only shortlisted candidates shall be contacted via email for interview. Expenses for appearing the interview shall be borne by the candidate concerned. National Law University Delhi reserves the right to not fill the position. National Law University Delhi is an equal opportunity employer.</td>
            </tr>
          </tbody>
        </table>
        <h3>How to Fill Application Form Step by Step Process</h3>
        <ul>
          <li>Click on the provided link for the application form.</li>
          <li>Enter your Full Name.</li>
          <li>Enter your Email ID.</li>
          <li>Enter Mobile No.</li>
          <li>Upload copy of Resume/CV.</li>
          <li>Upload sample of your writing published, or otherwise, write on an issue of law (not more than 2000 words) make a pdf file of it, and attach here and upload.</li>
          <li>Upload statement of purpose (not more than 500 words) describing why do you want to be a part of this opportunity.</li>
          <li>Click on the Submit button.</li>
        </ul>
        <p>You’ll receive a confirmation of successful submission either on the screen or via email.</p>
      </div>
    </div>
  );
};

export default Opportunities;
