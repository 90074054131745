import React from 'react';
import { Document, Page, pdfjs } from 'react-pdf';

// Set worker path
pdfjs.GlobalWorkerOptions.workerSrc = '/pdfjs/pdf.worker.min.js';

const Test = () => {
    return (
  // <Document
  //   file="/path/to/your/pdf"
  //   onLoadSuccess={({ numPages }) => console.log(`Loaded ${numPages} pages`)}
  //   onLoadError={(error) => console.error('Error loading PDF: ', error)}
  // >
  //   <Page pageNumber={1} />
  // </Document>
  <h1>;shdflaskjflasdjfhasldkdflhasldkblaskdd;jfasdfasdflkjasdhlkashdflkas
    f;sdakhflkasdhflasdhf
    ;fashdflkashdflkahsdlf.jfasdfasdflkjasdhlkashdflkaslahflkasdhf
    klashflasdhf
    alsjflasdfjh
  </h1>
    );
};

export default Test;